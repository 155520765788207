<!-- 工单 -->
<template>
  <common-layout class="h100">
    <template #header>
      <!-- <el-form inline>
        <el-form-item label="工单号">
          <el-input placeholder="请输入" v-model="form.ticketId" clearable></el-input>
        </el-form-item>
        <el-form-item label="企业账号">
          <el-input placeholder="请输入" v-model="form.enterpriseAccount" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
      </el-form> -->
      <el-tabs v-model="status" type="card" @tab-click="handleClickTab">
        <el-tab-pane label="全部" name="-1"></el-tab-pane>
        <el-tab-pane label="待处理" name="0"></el-tab-pane>
        <el-tab-pane label="已处理" name="1"></el-tab-pane>
        <el-tab-pane label="暂不处理" name="2"></el-tab-pane>
      </el-tabs>
    </template>
    <template>
      <el-table :data="tableData" v-loading="tableLoading" border stripe height="100%">
        <!-- <el-table-column label="模块名称" prop="" align="center"></el-table-column> -->
        <!-- <el-table-column label="地址" prop="" align="center"></el-table-column> -->
        <el-table-column label="需求场景" prop="source" align="center"></el-table-column>
        <el-table-column label="建议" prop="desc" align="center"></el-table-column>
        <el-table-column label="提问的企业账号" prop="enterpriseAccount" align="center"></el-table-column>
        <el-table-column label="状态" prop="status" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">待处理</span>
            <span v-if="scope.row.status == 1">已处理</span>
            <span v-if="scope.row.status == 2">暂不处理</span>
          </template>
        </el-table-column>
        <el-table-column label="图片" prop="" align="center">
          <template slot-scope="scope">
            <el-image v-for="(item, index) in scope.row.pics" :key="index" :src="item" style="width: 100px"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="提交人" prop="userAccount" align="center"></el-table-column>
        <el-table-column label="提交时间" prop="createTime" align="center"></el-table-column>
        <el-table-column label="处理人" prop="handledUser" align="center"></el-table-column>
        <el-table-column label="处理时间" prop="handledTime" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="150px">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdate(scope.row, 1)" v-if="scope.row.status == 0">处理完成</el-button>
            <el-button type="text" @click="handleUpdate(scope.row, 2)" v-if="scope.row.status == 0"> 暂不处理 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template #footer>
      <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </template>
  </common-layout>
</template>

<script>
import api from '@/api/operate/order'
export default {
  props: {
    type: {
      default: 1,
      type: Number,
    },
  },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      status: '-1',
      showReply: false,
      curRow: null,
    }
  },
  created() {
    this.query()
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
        useType: 1,
        type: 5,
      }
      this.tableLoading = true
      api
        .getAdviceOrder(params)
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleClickTab() {
      if (this.status != '-1') {
        this.form.status = this.status
      } else {
        this.form.status = ''
      }
      this.query()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
    handleUpdate(row, status) {
      this.$confirm(`确定${status == 1 ? '处理完成?' : '暂不处理?'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = {
          ...row,
          status,
        }
        api.updateAdviceOrder(params).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
